$font: 'Dosis', sans-serif;

#mate {
    font-family: $font;
    display: flex;
    justify-content: space-between;

    .planes {
        @media only screen and (max-width: 768px) {
            display: flex;
            flex-direction: column;
            img {
                width: 100%;
            }
        }
        
        @media only screen and (min-width: 768px) {
            display: flex;
            gap: 1.5%;
        }
    }

    img {
        width: 49%;
        margin-top: 0.7rem;
        border-radius: 5px;
    }
}

@media (max-device-width: 660px) {
    #mate {
        h1 {
            font-size: 8vh;
        }

        p {
            font-size: 5.2vh;
        }
    }
}
