#header-grafico {
    display: flex;
    align-items: center;

    button {
        margin-left: 2rem;
        font-size: 1rem;
        border-radius: 8px;
        padding: 0.4rem;

        @media (max-device-width: 660px) {
            font-size: 1.6rem;
        }
    }
}

#grafico-plan { 
    img {
        width: 60%;
        margin-top: 0.7rem;
        border-radius: 5px;
    }

    h2 {
      font-size: 2.1rem;
    }

    @media (max-device-width: 660px) {
        img {
            display: block;
            width: 100%;
        }
    }
}
