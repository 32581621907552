$font: 'Dosis', sans-serif;

#home {
    font-family: $font;
    display: flex;
    justify-content: space-between;

    h1 {
        font-size: 3rem;
        margin-bottom: 0.6rem;
    }

    p {
        font-size: 1.5rem;
        text-indent: 1em;
    }

    img {
        border-radius: 5px;
        width: 100%;
        margin-top: 0.7rem;
    }
}


@media (max-device-width: 660px) {
    #home {
        h1 {
            font-size: 8vh;
        }

        p {
            font-size: 5.2vh;
        }
    }
}
