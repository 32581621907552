$font: 'Dosis', sans-serif;

#datos {
    font-family: $font;
    display: flex;
    justify-content: space-between;

    img {
        width: 75%;
        margin-top: 0.7rem;
        border-radius: 5px;
    }
}

@media (max-device-width: 660px) {
    #datos {
        h1 {
            font-size: 8vh;
        }

        p {
            font-size: 5.2vh;
        }

        img {
            width: 100%;
        }
    }
}
