.content {
    width: 100%;

    h1 {
        font-size: 3rem;
        margin-bottom: 0.6rem;
    }

    p {
        font-size: 1.5rem;
        text-indent: 1em;
    }
}
