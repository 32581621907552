$font: 'Dosis', sans-serif;

@mixin clean-link() {
    text-decoration: none;
    color: inherit;
}

#footer {
    font-family: $font;

    img {
        width: 20px;
        margin: 3px;
    }

    #useful-links {
        display: flex;
        justify-content: space-between;

        #left-items {
            display: flex;
            align-items: center;

            #contacto {
                margin-left: 0.5rem;
            }    
        }
    }
}

@media (max-device-width: 660px) {
    #footer {
        img {
            width: 2.2rem;
            margin: 6px;
        }
    }
}

#nav {
    text-align: center;
    background-color: rgb(28, 28, 28);
    color: white;
    font-family: $font;
    min-width: 100vw;

    .title {
        font-size: 2.5rem;
        @include clean-link();
    }

    .pages-list {
        display: flex;
        list-style: none;
        justify-content: center;
        gap: 4.8rem;
        padding: 0.8rem;
        font-size: 1.6rem;

        .page {
            @include clean-link();
            font-size: 1.7rem;
            &:hover {
                color: rgb(188, 188, 188);
            }
        }
    }
}

#sidebar {
    width: 18rem;
    min-width: 18rem;
    margin-left: 1rem;
    font-family: $font;
    font-size: 1.6rem;
    font-weight: 350;
    background-color: rgb(215, 213, 77);
    min-height: 130vh;
    
    .side-items {
        list-style: none;
        padding-left: 0;
        padding-top: 0.5rem;
        padding-bottom: 1rem;
        text-align: center;

        .side-item {
            margin-top: 0.8rem;
            @media only screen and (min-width: 768px) {
                margin-top: 0.6rem;    
            }
            cursor: pointer;
        }
    }

    a {
        color: black;
        text-decoration: none;
    }
}

#instrucciones {
    img {
        display: block;
        margin: auto;
        margin-top: 4vh;
        width: 80%
    }
}

#mapas {
    font-family: $font;
    h1 {
        font-size: 3rem;
    }

    h2 {
        font-size: 2rem;
        margin-top: 0.6rem;
    }

    img {
        display: block;
        margin: auto;
        margin: 4vh;
        width: 90%
    }
}

@media (max-device-width: 660px) {
    #nav {
        .title {
            font-size: 11.5vh;
        }

        .pages-list {
            gap: 4.5rem;
            .page {
                font-size: 6.5vh;
            }

            padding-right: 1rem;
            padding-left: 1rem;

            padding-bottom: 1.2rem;
        }
    }

    #sidebar {
        font-size: 5.4vh;
        padding-left: 5px;
        padding-right: 5px;
        min-height: 250vh;
    }
}
