$font: 'Dosis', sans-serif;

#compu {
    font-family: $font;
    display: flex;
    justify-content: space-between;
    position: relative;

    #header-compu {
        display: flex;
        align-items: center;

        button {
            margin-left: 2rem;
            font-size: 1.4rem;
            border-radius: 8px;
            padding: 0.5rem;

            @media (max-device-width: 660px) {
                font-size: 1.6rem;
            }
        }
    }

    img {
        width: 65%;
        margin-top: 0.7rem;
        border-radius: 5px;
    }
}

@media (max-device-width: 660px) {
    #compu {
        h1 {
            font-size: 8vh;
        }

        p {
            font-size: 5.2vh;
        }

        img {
            display: block;
            width: 100%;
        }
    }
}
