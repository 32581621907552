$font: 'Dosis', sans-serif;

#fisica {
    font-family: $font;
    display: flex;
    justify-content: space-between;

    img {
        width: 80%;
        margin-top: 0.7rem;
        border-radius: 5px;
    }
}

@media (max-device-width: 660px) {
    #fisica {
        h1 {
            font-size: 8vh;
        }

        p {
            font-size: 5.2vh;
        }

        img {
            width: 100%;
        }
    }
}
